    import { useReducer, useEffect, useState } from "react";
import { reducer, initialState } from "../../../../services/store";
import { DataLoader, DATA_URL } from "../../../../services/dataLoader";
import { SessionStorageService, SS_KEYS } from "../../../../services/sessionStorage";
import { useLang } from "../../../../hooks/useLang";
import { ChessSection } from "../../../chessSection/ChessSection";

export function OurCoursesAndServices(){
    
    const {currentLanguage} = useLang();

    const [courseUrlList, setCourseUrlList] = useState(SessionStorageService.get(SS_KEYS.COURSES_URL_LIST) || null);

    useEffect(() => {
        const servicesLoader = async () => {
            await DataLoader.set(DATA_URL[`COURSES_URL_LIST_${currentLanguage}`], [SessionStorageService, SS_KEYS.COURSES_URL_LIST]);
            setCourseUrlList(SessionStorageService.get(SS_KEYS.COURSES_URL_LIST));
        }
        servicesLoader();
    }, []);

    return (
        <>
            {courseUrlList ?
                < ChessSection data={courseUrlList} />
            : null}
        </>
    );
}