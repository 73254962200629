
import './ai-section.css';

export function AISection(){

    return(
        <section className='ai-section'>
            <h4 className='ai-section__title'>
                РОЗМОВЛЯТИ З <span className='ai-section__title_blue'>ChatGPT </span>НА ТИ
            </h4>
            <div className='ai-section__video-container'>
                <video src="">

                </video>
            </div>
        </section>
    );
}