// import { useEffect, useState, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
// import { DataLoader, DATA_URL } from '../services/dataLoader';
import { AboutUsPage, BlogArticlePage, BlogListPage, ContactUsPage, Layout, MainPage, VacanciesPage,
        TermsOfUsePage, PrivacyPolicyPage, CourseDesignPage 
} from "./index";
import { ScrollToAnchor } from "./scrollToAnchour";
import { ScrollToTop } from "./scrollToTop";
// import { useLang } from "../hooks/useLang";
// import { reducer, initialState } from '../services/store';
// import { CourseDesignPage } from "./CoursePage";

export default function AppRoutes(){

    // const [state, dispatch] = useReducer(reducer, initialState);
    // const [currentCourse, setCurrentCourse] = useState('');
    // const {currentLanguage} = useLang();

    // useEffect(() => {
    //     const servicesLoader = async () => {
    //         await DataLoader.set(DATA_URL[`COURCES_URL_${currentLanguage}`]);
    //         setCurrentCourse(SessionStorageService.get(SS_KEYS.SERVICES));
    //     }
    //     servicesLoader();
    // }, []);

    return(
        <>
        <ScrollToAnchor />
        <ScrollToTop />
            <Routes>
                {
                    <Route path="/" element={<Layout />}>
                        {/* <Route path="/about-us#Careers" element={<AboutUsPage />}></Route> */}
                        {/* <Route path="/generative-AI#how-we-can-help" element={<AboutUsPage />}></Route> */}
                        <Route index element={<MainPage />}></Route>

                        <Route path="/about-us" element={<AboutUsPage />}></Route>
                        <Route path="/courses-and-events" element={<BlogListPage />}></Route>
                        <Route path="/article/:id" element={<BlogArticlePage />}></Route>

                        <Route path="/contact-us" element={<ContactUsPage />}></Route>
                        <Route path="/custom-templaate" element={<VacanciesPage />}></Route>
                        <Route path="/terms-of-use" element={<TermsOfUsePage />}></Route>
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>

                        <Route path="/ai-for-beginners" element={<CourseDesignPage />}></Route>
                        {/* <Route path="/ai-for-beginners" element={<PrivacyPolicyPage />}></Route> */}

                        <Route path="/#about-anchor" element={<MainPage />}></Route>
                    </Route>
                }
            </Routes>
        </>
    );
}