
import { useEffect } from 'react';
import uuid from 'react-uuid';
import { useWidth } from '../../../../hooks/useWidth';
import './cost-plans-section.css';

function CostPlan(props){

    const data = props.data
    const {currentWidth} = useWidth();

    let description;
    currentWidth < 768 ? description = data.courseShortDescription : description = data.courseDescription;

    return(
        <div className='cost-plan__element'>
            <div className='cost-plan__element-container'>
                <div className='cost-plan__element-image-container'>
                    <img src={data.courseImage} alt={data.courseImgAlt} />
                </div>
                <div className='cost-plan__element-description-container'>
                    <h5 className='cost-plan__element-title' dangerouslySetInnerHTML={{__html: data.courseTitle}}></h5>
                    <p className='cost-plan__element-description' dangerouslySetInnerHTML={{__html: description}}></p>
                    <div className='cost-plan__element-sale-info-container'>
                        <span className='cost-plan__element-price' dangerouslySetInnerHTML={{__html: data.coursePrice}}></span>
                        dsfsd
                        {
                            data.courseDiscount
                            ?
                            <div className='cost-plan__element-discount-container'>
                                <span dangerouslySetInnerHTML={{__html: data.courseDiscount}}></span>
                                <span dangerouslySetInnerHTML={{__html: data.courseDiscountСonditions}}></span>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export function CostPlanSection(){

    const data = [
        {
            courseTitle: "Standart",
            // courseImage: "/images/course images/AI-for-beginners.JPG",
            // courseImgAlt: "Роботизований мозок штучного інтелекту",
            coursePrice: "750 гривень",
            courseShortDescription: "'AI for Beginners' — курс для новачків, де ви навчитеся працювати з ChatGPT, створювати промпти й використовувати AI інструменти.",
            courseDescription: "'AI for Beginners' — це курс, створений спеціально для тих, хто лише починає знайомитися зі світом штучного інтелекту. Ви дізнаєтесь, як працювати з ChatGPT, розуміти його можливості та обмеження, створювати ефективні промпти для вирішення різних завдань. Курс охоплює основи роботи з AI-інструментами, що можуть стати у пригоді в навчанні, роботі чи повсякденному житті. Учасники отримають практичні поради та кейси, які допоможуть легко інтегрувати ці технології у свою діяльність. Підходить для всіх, хто хоче почати використовувати штучний інтелект для підвищення продуктивності. Почніть свій шлях у AI сьогодні!",
            courseBuyURL: "",
            courseTime: "320 хвилин",
            courseKeywords: ["chat GPT", "AI", "Штучний інтелект", "AI інструменти"],
            courseDiscount: "",
            courseDiscountСonditions: ""
        },
        {
            courseTitle: "Saless",
            // courseImage: "/images/course images/AI-for-beginners.JPG",
            coursePrice: "750 гривень",
            courseShortDescription: "'AI for Beginners' — курс для новачків, де ви навчитеся працювати з ChatGPT, створювати промпти й використовувати AI інструменти.",
            courseDescription: "'AI for Beginners' — це курс, створений спеціально для тих, хто лише починає знайомитися зі світом штучного інтелекту. Ви дізнаєтесь, як працювати з ChatGPT, розуміти його можливості та обмеження, створювати ефективні промпти для вирішення різних завдань. Курс охоплює основи роботи з AI-інструментами, що можуть стати у пригоді в навчанні, роботі чи повсякденному житті. Учасники отримають практичні поради та кейси, які допоможуть легко інтегрувати ці технології у свою діяльність. Підходить для всіх, хто хоче почати використовувати штучний інтелект для підвищення продуктивності. Почніть свій шлях у AI сьогодні!",
            courseBuyURL: "",
            courseTime: "320 хвилин",
            courseKeywords: ["chat GPT", "AI", "Штучний інтелект", "AI інструменти"],
            courseDiscount: "Знижка 50%",
            courseDiscountСonditions: "<a href=\"/\">*умови отримання знижки</a>"
        }
    ]

    return(
        <section className="cost-plan__section">
            <h4 className='cost-plan__title'>ТАРИФНІ <span className='cost-plan__title_blue'>ПЛАНИ</span></h4>
            <div className='cost-plan__container'>
                {
                    data.map((el) => (
                        <CostPlan data={el} key={uuid()} />
                    ))
                }
            </div>
        </section>
    );
}