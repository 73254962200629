import { useEffect, useState } from 'react';
import { useWidth } from '../../../../hooks/useWidth';
import './quote.css'

export function Quote(){

    const {currentWidth} = useWidth();
    const [qouteHeight, setQuoteHeight] = useState(0);

    useEffect(() => {
        const heightAbsContainer = document.querySelector('.quote__absolute-conainer').offsetHeight;
        setQuoteHeight(heightAbsContainer);
    }, [currentWidth]);

    return(
        <div className="quote" style={{height: `${qouteHeight}px`}}>
            <div className="quote__first-section" style={{height: `${qouteHeight/2}px`}}></div>
            <div className="quote__second-section" style={{height: `${qouteHeight/2}px`}}>
            </div>
            <div className="quote__absolute-conainer main-container">
                <h2>Розвиваймо Україну разом!</h2>
                <p>Навіть в найтемніші часи - знання стануть в нагоді, адже несуть світло в собі. 
                Ми активно займаємося соціальними проєктами та ініціативами, спрямованими на популяризацію технологій та їх використання на благо суспільства. Якщо ви хочете стати частиною нашої команди, ми будемо раді новим волонтерам! Ваша допомога буде надзвичайно цінною для розвитку проєктів, які сприяють розвитку освіти та інновацій у суспільстві. Приєднуйтесь до нас і станьте частиною спільноти, яка будує технологічне майбутнє  нашої країни разом!</p>
            </div>
        </div>
    );
}