import { MainInfoSection } from "./mainInfoSection/MainInfoSection";
import { SecondPreventSection } from "./secondPreventSection";
import { ForWhomThisCourseSection } from "./forWhomThisCourseSection";
import { WhatWillYouLearnSection } from "./whatWillYouLearnSection/WhatWillYouLearnSection";
import { AISection } from "./aiSection/AISection";
import { AboutCourseProgramSection } from "./aboutCourseProgramSection";
import { CostPlanSection } from "./costPlansSection";
import './course-page.css';

export function CoursePage() {

    return (
        <div className="course-page-container" style={{"backgroundColor": "#000"}}>
            <MainInfoSection />
            <SecondPreventSection />
            <ForWhomThisCourseSection />
            <WhatWillYouLearnSection />
            <AISection />
            <AboutCourseProgramSection />
            <CostPlanSection />
        </div>
    );
}