
export function AboutCourseProgramItem(props){

    const data = props.data;

    return(
        <div className='item'>
            <div className='item__count'>{data['count']}</div>
            <div className='item__text'>
                <p><span>{data['title-text']}</span><br />{data['text']?data['text']:null}</p>
            </div>
        </div>
    );
}