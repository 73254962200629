const SERVICES = [{name : 'Generative AI', link : '/'},
                  
                  {name : 'Career Consultation', link : '/career-consultation'}, 
                  {name : 'Boost your Career', link : '/boost-your-career'},]
const COMPANY = [{name : "About us", link : "/about-us"},
                 {name : "TENETIX.AI", link : "https://tenetix.ai"},
                 {name : "Privacy policy", link : "/privacy-policy"},
                 {name : "Terms of Use", link : "/terms-of-use"}]
export { SERVICES, COMPANY }

// {name : 'Corporate', link : '/corporate'}, 