
import uuid from 'react-uuid';
import { AboutCourseProgramItem } from './item';
import './about-coursep-program-section.css';

export function AboutCourseProgramSection(){

    const data = [
        {"count": 1, "title-text": "Вступ до  Штучного інтелекту ", "text": "Терміни та поняття, основи ШІ"},
        {"count": 2, "title-text": "Персональні асистенти та як з ними спілкуватись", "text": "Набір запитів «Вирішення рутинних завдань для різних професій»"},
        {"count": 3, "title-text": "Робота з зображеннями, відео, звуком", "text": "Інструменти для генерації зображень, відео, музики" },
        {"count": 4, "title-text": "ШІ як ваш персональний маркетолог", "text": "Як стати впізнаваним"},
        {"count": 5, "title-text": "Етичність використання ШІ та як не порушувати права", "text": ""},
        {"count": 6, "title-text": "Вплив ШІ на професії ", "text": "Як адаптуватись до змін та шаленого потоку інформації. Майбутнє технологій"}
    ];

    

    return(
        <section className='about-course-program-section'>
            <h4 className='about-course-program-section__title'>
                ДЕТАЛЬНІШЕ <span className='about-course-program-section__title_blue'>ПРО ПРОГРАМУ</span>
            </h4>
            <div className='about-course-program-section__content-container'>
                {
                    data ?
                    data.map(el => (
                        <AboutCourseProgramItem data={el} key={uuid()} />
                    ))
                    : null
                }
                {/* <div className='about-coursep-program-section__item-container'>
                    <div className='about-coursep-program-section__item-count'>1</div>
                    <div className='about-coursep-program-section__item-text'>
                        <p><span>Вступ до  Штучного інтелекту </span><br />Терміни та поняття, основи ШІ</p>
                    </div>
                </div> */}
            </div>

        </section>
    );
}